import React, { useEffect, useState } from 'react';
import AnimalIcon from './animal-icon';
import { AnimalsInformation } from '../../../utils/animals';

function AnimalSelector({ currentAnimalIndex, setCurrentAnimalIndex }) {
  const animals = AnimalsInformation;
  const [previousIndex, setPreviousIndex] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);

  useEffect(() => {
    const selector = document.getElementById("selector");
    const icon = document.getElementById("animal-icon-0");

    let maxScroll = 5;
    if (window.innerWidth <= 600) maxScroll = 2;

    // newIndex
    let newIndex = scrollIndex + (currentAnimalIndex - previousIndex);
    if (newIndex > maxScroll) newIndex = maxScroll;
    if (newIndex < 0) newIndex = 0;
    setScrollIndex(newIndex);

    // Set Previous Index
    setPreviousIndex(currentAnimalIndex);

    if (newIndex === maxScroll) selector.scrollLeft = (icon.offsetWidth + 10) * (currentAnimalIndex - maxScroll);
    else if (newIndex === 0) selector.scrollLeft = (icon.offsetWidth + 10) * currentAnimalIndex;

    // selector.scrollLeft = (icon.offsetWidth + 10) * (currentAnimalIndex - 6);
    // console.log(Math.round(selector.scrollLeft/(icon.offsetWidth + 10)));
    // setScrollIndex(Math.round(selector.scrollLeft/(icon.offsetWidth + 10)));
    // eslint-disable-next-line
  }, [currentAnimalIndex]);

  if (!animals) return <></>;

  const handleAnimalSelect = (index) => {
    setCurrentAnimalIndex(index);
  };

  return (
    <div className="flex flex-wrap gap-1 max-w-[840px] mx-auto" id="selector">
      {
        animals.map((animal, index) => {
          return (
            <AnimalIcon
              key={index}
              index={index}
              animal={animal}
              active={index === currentAnimalIndex}
              onClick={handleAnimalSelect}
            />
          )
        })
      }
    </div>
  );
}

export default AnimalSelector;