import React, { useState } from 'react';
import AnimalInformation from './animal-information';
import AnimalSelector from './animal-selector';

function Animals() {
  const [currentAnimalIndex, setCurrentAnimalIndex] = useState(0);

  return (
    <div className="overflow-hidden relative">
      <div className="absolute top-0 bg-cover bg-bottom inset-x-2/4 -translate-x-2/4 bg-[url(../public/images/bg-animals.webp)] w-full h-full">
      </div>
      <div className="relative top-0 w-full h-full container mx-auto sxl:py-24 md:py-16 md:pt-16 py-16">
        <div className="flex md:w-4/6 w-11/12 mb-8 mx-auto">
          <div className="w-full gap-4">
            <AnimalSelector
              currentAnimalIndex={currentAnimalIndex}
              setCurrentAnimalIndex={setCurrentAnimalIndex}
            />
          </div>
        </div>
        <div className="md:w-4/6 w-11/12 mx-auto md:flex block gap-4">
          <div className="md:w-full md:mt-0 mt-8 w-full">
            <AnimalInformation
              currentAnimalIndex={currentAnimalIndex}
              setCurrentAnimalIndex={setCurrentAnimalIndex}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Animals;