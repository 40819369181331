import React from 'react';

function MainButton({ text, onClick }) {
  return (
    <div className="group cursor-pointer" onClick={onClick}>
      <div className="relative top-0 group-hover:-top-4 transition-all duration-150 ease-in-out flex justify-center">
        <div className="flex group-hover:hidden">
          <div>
            <img
              src="/images/button-inactive-start.webp"
              alt="Inactive button start"
              className="h-full"
            />
          </div>
          <div className="py-5 bg-[url(../public/images/button-inactive-main.webp)] bg-repeat-x min-w-[280px]">
            <p className="font-style-game md:text-[30px] text-xl tracking-wide uppercase text-white text-center">{ text }</p>
          </div>
          <div>
            <img
              src="/images/button-inactive-end.webp"
              alt="Inactive button end"
              className="h-full"
            />
          </div>
        </div>
        <div className="hidden group-hover:flex">
          <div>
            <img
              src="/images/button-active-start.webp"
              alt="Active button start"
              className="h-full"
            />
          </div>
          <div className="py-5 bg-[url(../public/images/button-active-main.webp)] bg-repeat-x min-w-[280px]">
            <p className="font-style-game md:text-[30px] text-xl tracking-wide uppercase text-white text-center">{ text }</p>
          </div>
          <div>
            <img
              src="/images/button-active-end.webp"
              alt="Active button end"
              className="h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainButton;