import React from 'react';

function Subhero() {
  return (
    <div className="h-44 bg-slate-900 relative">
      <div className="container mx-auto h-full flex">
        <div className="md:block hidden absolute left-0 w-1/6 h-full">
          <img
            src="images/ducky.webp"
            alt="Owl Shaman"
            className="absolute bottom-0 w-auto"
          />
        </div>
        <div className="md:ml-5 mx-auto px-8 py-10 w-full">
          <p className="font-style-game md:text-[56px] text-[28px] text-center text-white text-shadow-normal">The most chaotic & fun multiplayer game of 2022!</p>
        </div>
      </div>
    </div>
  );
}

export default Subhero;