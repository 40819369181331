import React from 'react';

function ModeSection({ modes, position, setCurrentMode }) {
  const handleBackClick = () => {
    if (position > 0) setCurrentMode(position - 1);
  }

  const handleNextClick = () => {
    if (position < 3) setCurrentMode(position + 1);
  }

  return (
    <div className="flex relative overflow-clip max-h-screen">
      <div className="container mx-auto flex md:w-full w-11/12 gap-5 z-0">
        <div className="w-full mx-auto py-8">
          <div className="max-w-[1000px] mx-auto relative">
            <div className="flex md:gap-14 gap-2 md:flex-row flex-col">
              <div className="md:min-w-[80px] min-w-[48px] md:flex hidden">
                <img
                  src="images/animals/animal-ui-arrow.webp"
                  className="-scale-x-100 cursor-pointer self-center w-full"
                  alt="Arrow Left"
                  onClick={handleBackClick}
                  width="80"
                  height="80"
                />
              </div>
              {
                modes.length > 0 && modes.map((mode, index) => {
                  if (!mode || position !== index) return <></>;
                  return (
                    <div
                      className={`${position === index ? 'block' : 'hidden'} relative w-full h-[500px] md:max-h-[415px] max-h-[250px] transition-all duration-150 ease-in-out mb-4 bg-slate-900 shadow-md border-2 border-b-[4px] rounded-md border-slate-900 overflow-clip cursor-pointer relative transition-all duration-150 ease-in-out shadow-[0_0_4px_1px] shadow-white`}
                      key={mode.name}
                    >
                      <iframe
                        width="100%"
                        height="100%"
                        src={mode.embed}
                        title="Bombergrounds Battle Royale Mode"
                        frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  )
                })
              }
              <div className="md:min-w-[80px] min-w-[48px] md:flex hidden">
                <img
                  src="images/animals/animal-ui-arrow.webp"
                  alt="Arrow Right"
                  className="cursor-pointer w-full self-center"
                  onClick={handleNextClick}
                  width="80"
                  height="80"
                />
              </div>
              <div className="w-full md:hidden flex justify-center">
                <div className="max-w-[64px]">
                  <img
                    src="images/animals/animal-ui-arrow.webp"
                    className="-scale-x-100 cursor-pointer self-center w-full"
                    alt="Arrow Left"
                    onClick={handleBackClick}
                    width="36"
                    height="36"
                  />
                </div>
                <div className="max-w-[64px]">
                  <img
                    src="images/animals/animal-ui-arrow.webp"
                    alt="Arrow Right"
                    className="cursor-pointer w-full self-center"
                    onClick={handleNextClick}
                    width="36"
                    height="36"
                  />
                </div>
              </div>
            </div>
            <div className="flex p-4 pb-0">
              {
                modes.length > 0 && modes.map((mode, index) => {
                  if (!mode) return <></>;
                  return (
                    <div
                      key={mode.name}
                      className={`${position === index ? 'block' : 'hidden'} self-center w-full`}
                    >
                      <p className="font-style-game md:text-5xl text-3xl text-white text-center md:mt-6 mt-2 mb-2 uppercase">
                        { mode.name }
                      </p>
                      <p className="font-style-game md:text-2xl text-xl font-medium text-white text-center">
                        { mode.description }
                      </p>
                      <img
                        src={mode.icon}
                        alt="Mode Icon"
                        className={`w-auto mt-4 md:h-[96px] h-[100px] mx-auto`}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModeSection;