import React from 'react';
// import { Link } from "react-router-dom";

function Subfooter() {
  return (
    <div className="bg-slate-900">
      <div className="container mx-auto max-w-64 md:pu-24 py-16 md:hidden">
        <div className="max-w-xs sm:w-auto mx-auto p-4">
          <img
            src="images/bombergrounds-logo.webp"
            className="md:w-auto w-5/6 mx-auto"
            alt="Bombergrounds"
            width="192"
            height="77"
          />
        </div>
        <div className="md:w-4/6 sm:w-auto mx-auto p-4 md:flex block justify-center font-lato text-2xl md:mt-8">
          <a href="https://giganticduck.com/monetization/" target="_blank" rel="noreferrer" className="block px-8 text-gray hover:text-white font-style-game text-center mb-2">Monetization</a>
          {/* <a href="/" className="block px-8 text-gray hover:text-white font-style-game text-center mb-2">Loot Tables</a>
          <p className="text-gray md:block hidden">-</p> */}
          {/* <Link to="/monetization" className="block px-8 text-gray hover:text-white font-style-game text-center mb-2">Monetization</Link> */}
          {/* <p className="text-gray md:block hidden">-</p>
          <a href="/" className="block px-8 text-gray hover:text-white font-style-game text-center">Learn more</a> */}
        </div>
      </div>
      <div className="container mx-auto max-w-64 md:py-10 py-16 md:block hidden">
        <div className="flex">
          <div className="max-w-xs md:w-1/6 sm:w-auto mx-auto pr-16">
            <img
              src="images/bombergrounds-logo.webp"
              className="md:w-auto w-5/6 mx-auto"
              alt="Bombergrounds"
            />
          </div>
          <div className="md:w-5/6 sm:w-auto mx-auto md:flex md:flex-row flex-col md:gap-0 gap-2 block font-lato self-center justify-end pl-4">
            {/* <a href="/" className="block text-gray hover:text-white font-style-game text-center mr-8">Loot Tables</a> */}
            <a href="https://giganticduck.com/monetization/" target="_blank" rel="noreferrer" className="block text-gray hover:text-white font-style-game text-center mr-8">Monetization</a>
            {/* <Link to="/monetization" className="block text-gray hover:text-white font-style-game text-center mr-8">Monetization</Link> */}
            {/* <a href="/" className="block text-gray hover:text-white font-style-game text-center">Learn more</a> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subfooter;