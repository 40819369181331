import React from 'react';
import ResponsiveImage from '../image/responsive-image';

function AnimalIcon({ index, animal, active, onClick }) {
  return (
    <div id={`animal-icon-${index}`} className="cursor-pointer carousel-item md:w-[94px] w-[19%] inline-block">
      <div
        className={`cursor-pointer overflow-clip border-transparent shadow-md border-4 box-border rounded-[18px] ${active ? 'border-white' : ''}`}
        onClick={() => onClick(index)}
      >
        <div className="border-[2px] border-black rounded-[14px] relative overflow-clip">
          <ResponsiveImage
            src={animal.icon}
            srcsm={animal.icon_sm}
            alt={`Animal ${animal ? animal.name : 'Not Found'}`}
            className="relative top-0 left-0 z-[5]"
            width="82"
            height="82"
          />
          <ResponsiveImage
            src={`images/animals/${animal.rarity}-box.webp`}
            srcsm={`images/animals/${animal.rarity}-box-sm.webp`}
            className="absolute top-0 left-0"
            alt="Rarity background"
            width="82"
            height="82"
          />
          {/* <img
            src={animal.icon}
            alt={`Animal ${animal ? animal.name : 'Not Found'}`}
            className="relative top-0 left-0 z-[5]"
          />
          <img
            src={`images/animals/${animal.rarity}-box.webp`}
            className="absolute top-0 left-0"
            alt="Rarity background"
          /> */}
        </div>
      </div>
    </div>
  );
}

export default AnimalIcon;