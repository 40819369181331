import React from 'react';

function Crossplay() {
  return (
    <div className="h-auto bg-slate-900 relative">
      <div className="absolute top-0 left-0 bg-[url(../public/images/bg-crossplay.webp)] bg-cover bg-bottom bg-no-repeat w-full h-full">
      </div>
      <div className="absolute top-0 left-0 bg-[rgba(0,0,0,.4)] bg-cover bg-bottom bg-no-repeat w-full h-full">
      </div>
      <div className="container mx-auto w-full relative md:min-h-[1025px] min-h-[540px] relative flex">
        <div className="mx-auto absolute self-center w-full mt-20">
          <div className="mb-14">
            <p className="font-style-game uppercase md:text-6xl text-4xl text-center text-white text-shadow-normal">Super Cross-play</p>
          </div>
          <div className="w-5/6 mx-auto mb-14">
            <p className="font-style-game-small md:text-lg text-md text-center text-white text-shadow-normal md:mb-0 mb-4">Continue playing your account on any device with a Gigantic Duck ID. Play anywhere, anytime!</p>
            <p className="font-style-game-small md:text-lg text-md text-center text-white text-shadow-normal md:mb-0 mb-4">Create a Gigantic Duck ID in any of our games to gain the benefits of</p>
            <p className="font-style-game-small md:text-lg text-md text-center text-white text-shadow-normal">this new way of playing games, anywhere, anytime!</p>
          </div>
          <div className="max-w-xl sm:w-auto mx-auto md:p-4 md:px-4 px-8 grid grid-cols-3 md:gap-8 gap-2">
            <a
              href="https://store.steampowered.com/app/1104450/Bombergrounds_Battle_Royale/"
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <img
                src="images/steam-download.webp"
                className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out shadow-[0_0_10px_2px] shadow-white"
                alt="Steam"
                width="160"
                height="50"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/bombergrounds-battle-royale/id1476733308"
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <img
                src="images/appstore-download.webp"
                className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out shadow-[0_0_10px_2px] shadow-white"
                alt="App Store"
                width="160"
                height="50"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.Giganticduck.Bombergrounds"
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <img
                src="images/playstore-download-new.webp"
                className="md:w-full w-full mx-auto group-hover:-mt-2 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out shadow-[0_0_10px_2px] shadow-white"
                alt="Play Store"
                width="160"
                height="50"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Crossplay;