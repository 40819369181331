export const AnimalsInformation = [
  {
    name: 'Bjorn',
    rarity: 'common',
    icon: 'images/animals/animal-icon-bjorn.webp',
    icon_sm: 'images/animals/animal-icon-bjorn-sm.webp',
    description: 'Bears are carnivoran mammals of the family Ursidae. They are classified as caniforms, or doglike carnivorans.'
  },
  {
    name: 'Catty',
    rarity: 'common',
    icon: 'images/animals/animal-icon-catty.webp',
    icon_sm: 'images/animals/animal-icon-catty-sm.webp',
    description: 'The cat is a domestic species of small carnivorous mammal. It is the only domesticated species in the family Felidae.'
  },
  {
    name: 'Hoppy',
    rarity: 'common',
    icon: 'images/animals/animal-icon-hoppy.webp',
    icon_sm: 'images/animals/animal-icon-hoppy-sm.webp',
    description: 'Rabbits, also known as bunnies or bunny rabbits, are small mammals in the family Leporidae.'
  },
  {
    name: 'Snoozey',
    rarity: 'rare',
    icon: 'images/animals/animal-icon-snoozey.webp',
    icon_sm: 'images/animals/animal-icon-snoozey-sm.webp',
    description: 'The giant panda, also known as the panda bear, is a bear native to South Central China.'
  },
  {
    name: 'Pon',
    rarity: 'rare',
    icon: 'images/animals/animal-icon-pon.webp',
    icon_sm: 'images/animals/animal-icon-pon-sm.webp',
    description: 'Deer or true deer are hoofed ruminant mammals forming the family Cervidae.'
  },
  {
    name: 'Vivi',
    rarity: 'epic',
    icon: 'images/animals/animal-icon-vivi.webp',
    icon_sm: 'images/animals/animal-icon-vivi-sm.webp',
    description: 'The dog or domestic dog, is a domesticated descendant of the wolf which is characterized by an upturning tail.'
  },
  {
    name: 'Paw',
    rarity: 'epic',
    icon: 'images/animals/animal-icon-paw.webp',
    icon_sm: 'images/animals/animal-icon-paw-sm.webp',
    description: 'The tiger is the largest living cat species and a member of the genus Panthera.'
  },
  {
    name: 'Konnie',
    rarity: 'epic',
    icon: 'images/animals/animal-icon-konnie.webp',
    icon_sm: 'images/animals/animal-icon-konnie-sm.webp',
    description: 'The koala or, inaccurately, koala bear, is an arboreal herbivorous marsupial native to Australia.'
  },
  {
    name: 'Popper',
    rarity: 'legendary',
    icon: 'images/animals/animal-icon-popper.webp',
    icon_sm: 'images/animals/animal-icon-popper-sm.webp',
    description: 'Squirrels are members of the family Sciuridae, a family that includes small or medium-size rodents.'
  },
  {
    name: 'Fangs',
    rarity: 'legendary',
    icon: 'images/animals/animal-icon-fangs.webp',
    icon_sm: 'images/animals/animal-icon-fangs-sm.webp',
    description: 'Bats are mammals of the order Chiroptera. With their forelimbs adapted as wings, they are capable of true and sustained flight.'
  },
  {
    name: 'Chunkey',
    rarity: 'legendary',
    icon: 'images/animals/animal-icon-chunkey.webp',
    icon_sm: 'images/animals/animal-icon-chunkey-sm.webp',
    description: "The raccoon is sometimes called the common raccoon to distinguish it from other species. That's why it's easy to mistake chunkey as a raccoon because he's actually a red panda."
  },
  {
    name: 'Rey',
    rarity: 'mythic',
    icon: 'images/animals/animal-icon-rey.webp',
    icon_sm: 'images/animals/animal-icon-rey-sm.webp',
    description: 'The wolf, also known as the gray wolf or grey wolf is a large canine native to Eurasia and North America.'
  },
  {
    name: 'Kong',
    rarity: 'mythic',
    icon: 'images/animals/animal-icon-kong.webp',
    icon_sm: 'images/animals/animal-icon-kong-sm.webp',
    description: 'Gorillas are herbivorous, predominantly ground-dwelling great apes that inhabit the tropical forests of equatorial Africa.'
  },
  {
    name: 'Gruff',
    rarity: 'mythic',
    icon: 'images/animals/animal-icon-gruff.webp',
    icon_sm: 'images/animals/animal-icon-gruff-sm.webp',
    description: 'Sheep are quadrupedal ruminant mammals typically kept as livestock.'
  },
  {
    name: 'Iggy',
    rarity: 'mythic',
    icon: 'images/animals/animal-icon-iggy.webp',
    icon_sm: 'images/animals/animal-icon-iggy-sm.webp',
    description: "Lizards are a widespread group of squamate reptiles with over 6,000 species, reanging across all continents except Antarctica."
  },
];