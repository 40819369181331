import React from 'react';

function ResponsiveImage({src, srcsm, alt, ...other}) {
  return (
    <picture>
      <source media="(min-width:526px)" srcSet={src} />
      <source media="(max-width:525px)" srcSet={srcsm ?? src} />
      <img src={src} alt={alt} {...other} />
    </picture>
  );
}

export default ResponsiveImage;